import { put, takeLatest } from 'redux-saga/effects';

import { startTest, failedTest, successTest } from '../slices/testSlice';

function* startTestSaga({ payload }) {
  try {
    console.log(payload);
    yield put(successTest({ data: { name: payload } }));
  } catch (e) {
    yield put(failedTest(e.message));
  }
}

export function* watchTestSaga() {
  yield takeLatest(startTest, startTestSaga);
}
