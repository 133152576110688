import { combineReducers } from '@reduxjs/toolkit';
import testReducer from '../slices/testSlice';
import alertsReducers from "../components/common/SweetAlert/reducers/sweet_alert";

const appReducers = combineReducers({
  test: testReducer,
  alerts: alertsReducers,
});

/**
 * Reset all state of all reducers.
 * */
const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    state = undefined;
  }
  return appReducers(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
