/* eslint-disable import/prefer-default-export,max-len */
import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { LogLevel, Logger } from 'msal';

const logger = new Logger(
  (logLevel, message, containsPii) => {
    console.log('[MSAL]', message);
  },
  {
    level: LogLevel.Verbose,
    piiLoggingEnabled: false
  }
);

const b2cPolicies = {
  authorities: {
    signIn: {
      authority: process.env.REACT_APP_AAD_AUTHORITY_URL_LOGIN,
    },
    forgotPassword: {
      authority: process.env.REACT_APP_AAD_AUTHORITY_URL_RESET_PASSWORD,
    },
  },
};

const authProvider = new MsalAuthProvider(
  {
    auth: {
      authority: b2cPolicies.authorities.signIn.authority,
      clientId: process.env.REACT_APP_AAD_CLIENT_ID,
      postLogoutRedirectUri: window.location.origin,
      redirectUri: process.env.REACT_APP_AAD_REDIRECT_URL,
      validateAuthority: false,
    },
    system: {
      logger: logger as any
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false
    }
  },
  {
    scopes: [process.env.REACT_APP_AAD_URL_IMPERSONATION],
  },
  {
    loginType: LoginType.Redirect,
    tokenRefreshUri: `${window.location.origin}/auth.html`
  }
);

const handleError = (error) => {
  if (error?.errorMessage.indexOf('AADB2C90118') > -1) {
    authProvider.setAuthenticationParameters({ authority: b2cPolicies.authorities.forgotPassword.authority });
    authProvider.login();
  }
  // Renewal token exception is thrown so will be necessary to launch login flow again. It's error happen on forgot password user flow
  if (error?.errorCode === 'token_renewal_error') {
    authProvider.setAuthenticationParameters({ authority: b2cPolicies.authorities.signIn.authority });
    authProvider.login();
  }
};

authProvider.registerErrorHandler(handleError);

export { authProvider };
