import {
    put, takeLatest,
} from 'redux-saga/effects';
import { ADD_SWEET_ALERT, successAddSweetAlert } from '../actions/sweet_alert';

export function* showAlert({ payload }) {
    yield put(successAddSweetAlert(payload));
}

export function* watchSweetAlert() {
    yield takeLatest(ADD_SWEET_ALERT, showAlert);
}
