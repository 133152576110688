import type { FC, ReactNode } from 'react';
import React, { createContext, useEffect, useReducer } from 'react';
import { AuthenticationState, IAccountInfo } from 'react-aad-msal';

import SplashScreen from 'src/components/SplashScreen';
import axios from 'src/utils/api';
import { useHistory } from 'react-router-dom';

import { authProvider } from '../views/auth/config/authProvider';
import { RolesEnum } from '../utils/enums';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: IAccountInfo | null;
}

interface AuthContextValue extends AuthState {
  method: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (email: string, name: string, password: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: IAccountInfo | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: IAccountInfo;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: IAccountInfo;
  };
};

type Action =
  | InitialiseAction
  | LoginAction
  | LogoutAction
  | RegisterAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        // isInitialised: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const history = useHistory();

  const login = async (email: string, password: string) => {
    // TODO: not necessary yet.
  };

  const logout = () => {
    setSession(null);
    authProvider.logout();
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email: string, name: string, password: string) => {
    // TODO: implement this when register feature is available.
  };

  useEffect(() => {
    const initialise = (): void => {
      try {
        const accountInfo: IAccountInfo = authProvider.getAccountInfo();
        if (authProvider.authenticationState === AuthenticationState.Authenticated) {
          const roleUSer = accountInfo.account.idTokenClaims.extension_Role;
          if (roleUSer === RolesEnum.Vendedor || roleUSer === RolesEnum.Broker) {
            dispatch({
              type: 'INITIALISE',
              payload: {
                isAuthenticated: false,
                user: null
              }
            });
            history.push('/access-denied');
          } else {
            setSession(accountInfo?.jwtIdToken);
            dispatch({
              type: 'INITIALISE',
              payload: {
                isAuthenticated: true,
                user: accountInfo,
              }
            });
          }
        } else if (authProvider?.authenticationState === AuthenticationState.Unauthenticated
            && !authProvider.getError()) {
          authProvider.login();
        }
      } catch (err) {
        console.error('Error Initialized UseEffect: ', err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };
    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
