import { createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface TestData {
  test: string;
}

export interface TestInterface {
  loading?: boolean;
  data?: object;
  error?: boolean;
}

const initialState: TestInterface = {
  loading: false,
  data: {},
  error: false,
};

const testSlice = createSlice({
  name: 'test',
  initialState,
  reducers: {
    startTest(state, action: PayloadAction<TestData>) {
      state.loading = true;
    },
    successTest(state, action: PayloadAction<TestInterface>) {
      state.loading = false;
      state.data = action.payload;
      state.error = false;
    },
    failedTest(state, action) {
      state.loading = false;
      state.error = true;
      state.data = action.payload;
    }
  }
});

export const { startTest, successTest, failedTest } = testSlice.actions;
export default testSlice.reducer;

