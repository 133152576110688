/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  makeStyles
} from '@material-ui/core';
import {
  FileMinus as FileMinusIcon,
  Users as UsersIcon,
  Lock as LockIcon,
} from 'react-feather';
import PersonIcon from '@material-ui/icons/Person';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import useFetch from "../../../hooks/useFetch";
import api from "../../../utils/api";
import {MenuType} from "../../../enums/menu-type";

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Section {
  menus: Section[];
  description: string;
  id: number;
  url?: string;
}

function renderNavItems({
  items,
  pathname,
  depth = 0
}: {
  items: Section[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}: {
  acc: any[];
  pathname: string;
  item: Section;
  depth: number;
}) {
  const key = item.description + depth;

  if (item.menus) {
    const open = matchPath(pathname, {
      path: item.url,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        key={key}
        open={Boolean(open)}
        title={item.description}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.menus
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.url}
        key={key}
        title={item.description}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const { response, error, status } = useFetch({ api, url: `${process.env.REACT_APP_AUTHORIZATIONS_URL}/menus/admin`, config: null });
  const [sections, setSections] = React.useState<Section[]>([]);

  useEffect(() => {
    if (response ) {
      const menus = response as Section[];
      const filteredMenus: Section[] = [];
      if (menus.length > 0) {
        menus.map((item) => {
          if(item.menus.length > 0) {
            filteredMenus.push(item);
          }
        });
        setSections(filteredMenus);
      }
    }

  }, [response]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
              >
                <PersonIcon fontSize="large" />
              </Avatar>
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/management/emissions"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.account.name}
            </Link>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections?.map((section) => (
            <List
              key={section.description}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.description}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.menus,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
