import {
    ADD_SWEET_ALERT,
    SUCCESS_ADD_SWEET_ALERT,
    CLOSE_SWEET_ALERT,
} from '../actions/sweet_alert';

const initialState = {
    alert: {},
    showAlert: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_SWEET_ALERT:
            return {
                ...state,
                alert: {},
                showAlert: false,
            };
        case SUCCESS_ADD_SWEET_ALERT:
            return {
                ...state,
                alert: action.payload,
                showAlert: true,
            };
        case CLOSE_SWEET_ALERT:
            return {
                ...state,
                alert: {},
                showAlert: false,
            };
        default:
            return state;
    }
};

export const getVisibleAlert = (state) => (
    (state.alerts && state.alerts.length > 0) ? state.alerts[0] : null
);
