import axios from 'axios';
import { configure } from 'axios-hooks';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((config) => {
  config.headers['Ocp-Apim-Subscription-Key'] = `${process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY}`;
  return config;
},
(error) => {
  console.log('Catched Request Error:', error);
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

configure({ axios: axiosInstance, cache: false });

export default axiosInstance;
