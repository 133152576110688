import { all, fork } from 'redux-saga/effects';
import testSagas from '../sagas';
import { sweetAlertSaga } from '../components/common/SweetAlert/sagas';

export default function* rootSaga() {
  yield all(
    [
      ...Object.values(testSagas),
      ...Object.values(sweetAlertSaga),
    ].map(fork),
  );
}
