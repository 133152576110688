export enum StatusFetch {
  Idle = 'IDLE',
  Success = 'SUCCESS',
  Pending = 'PENDING',
  Error = 'ERROR',
}

export enum RolesEnum {
  Vendedor = 'vendedor',
  Broker = 'broker'
}

export enum HttpStatus {
  HTTP_STATUS_OK = 200,
  INTERNAL_SERVER_ERROR = 500,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  CONFLICT = 409,
}
